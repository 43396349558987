import * as React from 'react';
import { Descriptions, message, Spin, Modal } from 'antd';
import { Aluno } from 'models/Aluno';
import { carregarDadosAluno } from './actions';
import { removeMascaraCpf } from 'utils/masks';

interface InjectedProps {
    showModal: (visible: boolean) => void;
    modalVisible: boolean;
    cpf: string;
}

export const Detalhes = (props: InjectedProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [aluno, setAluno] = React.useState<Aluno>();

    React.useEffect(() => {
        setLoading(true);
        if(props.cpf) {
            carregarDadosAluno(removeMascaraCpf(props.cpf))
            .then((resposta) => {
                setAluno(resposta);
            })
            .catch((erro) => {
                message.error("Erro ao carregar dados do aluno.");
            });
        }
        setLoading(false);
    }, [props.cpf])

    return (
        <Spin spinning={loading}>
            <Modal
                visible={props.modalVisible}
                title={aluno?.nome?.toUpperCase()}
                onCancel={() => props.showModal(false)}
                width={720}
            >
                <Descriptions
                    title={aluno?.nome}
                    bordered>
                    <Descriptions.Item label="Alergia">{aluno?.alergia}</Descriptions.Item>
                    <Descriptions.Item label="Atividade Física">{aluno?.atividadeFisica}</Descriptions.Item>
                    <Descriptions.Item label="Bairro">{aluno?.bairro}</Descriptions.Item>
                    <Descriptions.Item label="CEP">{aluno?.cep}</Descriptions.Item>
                    <Descriptions.Item label="Cidade">{aluno?.cidade}</Descriptions.Item>
                    <Descriptions.Item label="Cirurgia">{aluno?.cirurgia}</Descriptions.Item>
                    <Descriptions.Item label="Complemento">{aluno?.complemento}</Descriptions.Item>
                    <Descriptions.Item label="CPF">{aluno?.cpf}</Descriptions.Item>
                    <Descriptions.Item label="Dieta">{aluno?.dieta}</Descriptions.Item>
                    <Descriptions.Item label="Doença Família">{aluno?.doencaFamilia}</Descriptions.Item>
                    <Descriptions.Item label="Doença Pessoal">{aluno?.doencaPessoal}</Descriptions.Item>
                    <Descriptions.Item label="Dor no Corpo">{aluno?.dorNoCorpo}</Descriptions.Item>
                    <Descriptions.Item label="Data de Nascimento">{aluno?.dtNascimento}</Descriptions.Item>
                    <Descriptions.Item label="E-mail">{aluno?.email}</Descriptions.Item>
                    <Descriptions.Item label="Endereço">{aluno?.endereco}</Descriptions.Item>
                    <Descriptions.Item label="Etnia">{aluno?.etnia}</Descriptions.Item>
                    <Descriptions.Item label="Fumante">{aluno?.fumante}</Descriptions.Item>
                    <Descriptions.Item label="Medicamento">{aluno?.medicamento}</Descriptions.Item>
                    <Descriptions.Item label="Objetivo">{aluno?.objetivo}</Descriptions.Item>
                    <Descriptions.Item label="Observações">{aluno?.observacoes}</Descriptions.Item>
                    <Descriptions.Item label="Osteomuscular">{aluno?.osteomuscular}</Descriptions.Item>
                    <Descriptions.Item label="Outro">{aluno?.outro}</Descriptions.Item>
                    <Descriptions.Item label="Responsável">{aluno?.responsavel}</Descriptions.Item>
                    <Descriptions.Item label="Restrição">{aluno?.restricao}</Descriptions.Item>
                    <Descriptions.Item label="RG">{aluno?.rg}</Descriptions.Item>
                    <Descriptions.Item label="Segundo Telefone">{aluno?.segundoTelefone}</Descriptions.Item>
                    <Descriptions.Item label="Sexo">{aluno?.sexo}</Descriptions.Item>
                    <Descriptions.Item label="Telefone">{aluno?.telefone}</Descriptions.Item>
                    <Descriptions.Item label="UF">{aluno?.uf}</Descriptions.Item>
                </Descriptions>
            </Modal>
        </Spin>
    )
}