import * as Observable from 'rxjs';
import { endpointExercicios } from 'api/api';

export interface ListaGrupoMuscular {
    id: number;
    nome: string;
}

export function carregarGruposMusculares() {
    return Observable.defer(() => doCarregarGruposMusculares()).toPromise();
}

async function doCarregarGruposMusculares() {
    const result = await endpointExercicios.get('/grupomuscular/listar');
    return result.data.map((r: ListaGrupoMuscular) => {
        return {
            id: r.id,
            nome: r.nome,
        } as ListaGrupoMuscular
    }) as ListaGrupoMuscular[];
}