import * as React from 'react';
import { Card, List, message, Spin } from 'antd';
import { listaAniversariantesDoMes, Aniversariante } from './actions';
import moment from 'moment';

export const AniversariantesDoMes = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [aniversariantes, setAniversariantes] = React.useState<Aniversariante[]>([]);

    React.useEffect(() => {
        setLoading(true);
        listaAniversariantesDoMes()
            .then((resposta) => {
                setAniversariantes(resposta);
            })
            .catch((err) => {
                message.error("Erro ao listar aniversariantes.");
            });
        setLoading(false);
    }, []);

    return (
        <Card title="ANIVERSARIANTES DO MÊS"
            bordered={false}>
            <Spin spinning={loading}>
                <List
                dataSource={aniversariantes}
                renderItem={a => (
                    <List.Item>
                      {a.nome} - {moment(a.aniversario).format("[dia] DD")}
                    </List.Item>
                  )}>
                </List>
            </Spin>
        </Card>
    );
}