import { endpointAlunos } from '../../../api/api';
import * as Observable from 'rxjs';
import { mascaraCpf, mascaraTelefone } from 'utils/masks';

export interface ListaAlunos {
    cpf: string;
    nome: string;
    telefone: string;
    segundoTelefone: string;
}

export function carregarTabela() {
    return Observable.defer(() => doCarregarTabela()).toPromise();
}

async function doCarregarTabela() {
    const result = await endpointAlunos.get('/listar');
    return result.data.map(( r: ListaAlunos ) => {
        return {
            cpf: mascaraCpf(r.cpf),
            nome: r.nome,
            telefone: mascaraTelefone(r.telefone),
            segundoTelefone: mascaraTelefone(r.segundoTelefone),
        } as ListaAlunos;
    }) as ListaAlunos[];
}

export function procurarAlunos(param: string) {
    return Observable.defer(() => doProcurarAluno(param)).toPromise();
}

async function doProcurarAluno(param: string) {
    const result = await endpointAlunos.post('/listar', param);
    return result.data.map(( r: ListaAlunos ) => {
        return {
            cpf: mascaraCpf(r.cpf),
            nome: r.nome,
            telefone: mascaraTelefone(r.telefone),
            segundoTelefone: mascaraTelefone(r.segundoTelefone),
        } as ListaAlunos;
    }) as ListaAlunos[];
}