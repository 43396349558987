import * as Observable from 'rxjs';
import { endpointExercicios } from 'api/api';

export interface ListaExercicio {
    id: string,
    nome: string
    grupoMuscular: string,
}

export function carregarTabela() {
    return Observable.defer(() => doCarregarTabela()).toPromise();
}

async function doCarregarTabela() {
    const result = await endpointExercicios.get('/listar');
    return result.data.map(( r: ListaExercicio ) => {
        return {
            id: r.id,
            nome: r.nome,
            grupoMuscular: r.grupoMuscular,
        } as ListaExercicio;
    }) as ListaExercicio[];
}

export function procurarExercicio(param: string) {
    return Observable.defer(() => doProcurarExercicio(param)).toPromise();
}

async function doProcurarExercicio(param: string) {
    const result = await endpointExercicios.post('/listar', param);
    return result.data.map(( r: ListaExercicio ) => {
        return {
            id: r.id,
            nome: r.nome,
            grupoMuscular: r.grupoMuscular,
        } as ListaExercicio;
    }) as ListaExercicio[];
}