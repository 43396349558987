import React from 'react';
import { Table, Input, Spin, message, Radio } from 'antd';
import { ColunasFinancas } from './columns';
import { carregarTabela, pesquisarMovimentos } from './actions';
import { Movimento } from 'models/Movimentos';

const { Search } = Input;

interface PesquisarForm {
  descricao: string;
  status: string;
}

export const TabelaFinancas = () => {

  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<Movimento[]>([]);
  const [status, setStatus] = React.useState<string>('TUDO');
  const [descricao, setDescricao] = React.useState<string>('');

  React.useEffect(() => {
    setLoading(true);
    carregarTabela()
      .then((resposta) => {
        setDataSource(resposta);
      })
      .catch((erro) => {
        console.log(erro);
        message.error("Erro inesperado.");
      });
    setLoading(false);
  }, []);

  const pesquisar = () => {
    setLoading(true);
    const form: PesquisarForm = {
      descricao: descricao,
      status: status
    }
    pesquisarMovimentos(form)
      .then((resposta) => {
        setDataSource(resposta);
      })
      .catch((erro) => {
        message.error("Erro inesperado.");
        console.log(erro);
      });
    setLoading(false);
  }

  return (
    <>
      <Search
        placeholder="Pesquisar"
        onSearch={pesquisar}
        style={{ width: 200 }}
        onChange={(e) => setDescricao(e.target.value)}
      />
      <Radio.Group
        onChange={(e) => setStatus(e.target.value)}>
        <Radio value={'TUDO'}>Tudo</Radio>
        <Radio value={'PAGO'}>Pago</Radio>
        <Radio value={'ATRASADO'}>Atrasado</Radio>
        <Radio value={'ABERTO'}>Aberto</Radio>
      </Radio.Group>
      <Spin spinning={loading}>
        <Table
          dataSource={dataSource}
          columns={ColunasFinancas()}
        />
      </Spin>
    </>
  );
}