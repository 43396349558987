const axios = require('axios');

export const endpointAlunos = axios.create({
    baseURL: 'http://localhost:8080/aluno'
});

export const endpointExercicios = axios.create({
    baseURL: 'http://localhost:8080/exercicio'
});

export const endpointFinancas = axios.create({
    baseURL: 'http://localhost:8080/movimentos'
});

export const endpointUsuario = axios.create({
    baseURL: 'http://localhost:8080/usuario'
})

axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';