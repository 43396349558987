import React from 'react';
import { Row, Col } from 'antd';
import { AniversariantesDoMes } from 'components/aniversariantes';

export const Home = () => {
    return (
        <>
            <Row>
                <Col>
                    <AniversariantesDoMes />
                </Col>
                <Col></Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
            </Row>
        </>
    )
}