import { Input, Table, Radio } from 'antd';
import React from 'react';

const { Search } = Input;

const dataSource = [
    {
      key: '1',
      nome: 'Aluno X',
      status: 'Aberto',
      valor: 'R$400,00',
      contrato: 'Premium 1',
      vencimento: '15/04/2020',
    },
    {
        key: '2',
        nome: 'Aluno Y',
        status: 'Adiantado',
        valor: 'R$250,00',
        contrato: 'Simples 1',
        vencimento: '15/06/2020',
      },
      {
        key: '3',
        nome: 'Aluno Z',
        status: 'Atrasado',
        valor: 'R$320,00',
        contrato: 'Simples 1',
        vencimento: '15/02/2020',
      },
      {
        key: '4',
        nome: 'Aluno Z',
        status: 'Atrasado',
        valor: 'R$290,00',
        contrato: 'Simples 1',
        vencimento: '15/03/2020',
      },
  ];
  
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
        title: 'Valor',
        dataIndex: 'valor',
        key: 'valor',
      },
      {
        title: 'Contrato',
        dataIndex: 'contrato',
        key: 'contrato',
      },
      {
        title: 'Vencimento',
        dataIndex: 'vencimento',
        key: 'vencimento',
      },
  ];
  
  
  export const TabelaMensalidades = () => {
      return (
        <>
            <Search
                placeholder="Pesquisar"
                onSearch={value => console.log(value)}
                style={{ width: 200 }}
            />
            <Radio.Group>
                <Radio value={1}>Tudo</Radio>
                <Radio value={2}>Pago</Radio>
                <Radio value={3}>Atrasado</Radio>
                <Radio value={4}>Aberto</Radio>
            </Radio.Group>
            <Table
                dataSource={dataSource}
                columns={columns}
            />
            <p>Total: R$1260,00</p>
        </>
    );
}