import { ColumnProps } from "antd/lib/table";

export const colunasExercicio: () => ColumnProps<any>[] = () => {
    return [
        {
            title: "Nome",
            dataIndex: 'nome',
            key: 'nome',
        },
    ];
}