import { Tabs } from "antd";
import React from 'react';
import { CadastrarAluno } from './CadastrarAluno';
import { ProcurarAluno } from './ProcurarAluno';

const { TabPane } = Tabs;

export default function Alunos() {
    return (
        <Tabs type="card">
            <TabPane tab="Cadastrar" key="1">
                <CadastrarAluno />
            </TabPane>
            <TabPane tab="Procurar" key="2">
                <ProcurarAluno />
            </TabPane>
        </Tabs>
    )
}