import { Form, Input, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

export default function CamposAluno() {
    return (
        <>
            <Form.Item name="responsavel" label="Responsável" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="etnia" label="Etnia">
                <Input />
            </Form.Item>
            <Form.Item name="observacoes" label="Observacoes">
                <TextArea rows={3} />
            </Form.Item>
            <Form.Item name="doencaFamilia" label="Doença na família">
                <Input />
            </Form.Item>
            <Form.Item name="doencaPessoal" label="Doença pessoal">
                <Input />
            </Form.Item>
            <Form.Item name="restricao" label="Restrição">
                <Input />
            </Form.Item>
            <Form.Item name="cirurgia" label="Cirurgia">
                <Input />
            </Form.Item>
            <Form.Item name="alergia" label="Alergia">
                <Input />
            </Form.Item>
            <Form.Item name="osteomuscular" label="Osteomuscular">
                <Input />
            </Form.Item>
            <Form.Item name="medicamento" label="Medicamento">
                <Input />
            </Form.Item>
            <Form.Item name="dorNoCorpo" label="Dor no Corpo">
                <Input />
            </Form.Item>
            <Form.Item name="fumante" label="Fumante">
                <Radio.Group>
                    <Radio value="true">Sim</Radio>
                    <Radio value="false">Não</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="dieta" label="Dieta">
                <TextArea rows={3} />
            </Form.Item>
            <Form.Item name="atividadeFisica" label="Atividade Física">
                <TextArea rows={3} />
            </Form.Item>
            <Form.Item name="outro" label="Outro">
                <TextArea rows={3} />
            </Form.Item>
            <Form.Item name="objetivo" label="Objetivo">
                <Input />
            </Form.Item>
        </>
    );
}