import * as Observable from 'rxjs';
import { endpointAlunos } from 'api/api';

export function cadastrarAluno(values: any) {
    return Observable.defer(() => doCadastrarAluno(values)).toPromise();
}

async function doCadastrarAluno(values: any) {
    const result = await endpointAlunos.post('', values);
    return result;
}