import { Form, Col, Row, Button, message } from "antd";
import React from 'react';
import CamposAluno from "../../../components/fields/CamposAluno";
import CamposPessoa from "../../../components/fields/CamposPessoa";
import { cadastrarAluno } from "./actions";

export const CadastrarAluno = () => {
    const [ form ] = Form.useForm();

    const onFinish = (values: any) => {
        cadastrarAluno(values)
        .then( () => {
            form.resetFields();
            message.success("Cadastro realizado com sucesso.")
        })
        .catch( (error: { response: { data: string; status: any; headers: any; }; request: any; message: any; config: any; }) => {
            if (error.response) {
                console.log(error.response.data);
                message.error("Erro. " + error.response.data);
              } else if (error.request) {
                console.log(error.request);
                message.error("Erro inesperado.");
              } else {
                console.log('Error', error.message);
                message.error("Erro inesperado.");
              }
              console.log(error.config);
        });
    }

    return (
        <Form
            onFinish={onFinish}
            style={{ width: "100%" }}
        >
            <Row>
                <Col span={12}>
                    <CamposPessoa />
                </Col>
                <Col span={12}>
                    <CamposAluno />
                </Col>
            </Row>
            <Button type="primary" htmlType="submit">
                Cadastrar
            </Button>
        </Form >
    );
}