import { Input, Table, Spin, message } from 'antd';
import React from 'react';
import { ListaAlunos, carregarTabela, procurarAlunos } from './actions';
import { ColunasPesquisa } from './columns';
import { Detalhes } from '../Detalhes';

const { Search } = Input;

export const ProcurarAluno = () => {

  const [loading, setLoading] = React.useState<boolean>(false);
  const [listaAlunos, setListaAlunos] = React.useState<ListaAlunos[]>([]);
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [selectedCpf, setSelectedCpf] = React.useState<string>('');

  const handleOnSearch = (value: any) => {
    setLoading(true);
    procurarAlunos(value)
      .then((resposta) => {
        setListaAlunos(resposta);
      })
      .catch((error) => {
        setListaAlunos([]);
        message.error("Erro inesperado.");
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const handleRowClick = (cpf: string) => {
    setSelectedCpf(cpf);
    showModal(true);
  }

  const showModal = (visible: boolean) => {
    setModalVisible(visible);
  }

  React.useEffect(() => {
    setLoading(true);
    carregarTabela()
      .then((resposta) => {
        setListaAlunos(resposta);
      })
      .catch((error) => {
        message.error("Erro inesperado.");
        setListaAlunos([]);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <Search
          placeholder="Pesquisar"
          onSearch={handleOnSearch}
          enterButton
          style={{ width: 200 }}
        />
        <Table
          onRow={(record) => ({
            onClick: () => {
              handleRowClick(record.cpf);
            },
          })}
          dataSource={listaAlunos}
          columns={ColunasPesquisa()}
          rowKey='cpf'
        />
      </Spin>
      <Detalhes
        showModal={showModal}
        modalVisible={modalVisible}
        cpf={selectedCpf}
      />
    </>
  );
}