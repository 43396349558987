import { ColumnProps } from "antd/lib/table";

export const ColunasPesquisa: () => ColumnProps<any>[] = () => {
    return [
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
        },
        {
            title: 'Telefone',
            dataIndex: 'telefone',
            key: 'telefone',
            align: 'center',
        },
        {
            title: 'Telefone(2)',
            dataIndex: 'segundoTelefone',
            key: 'segundoTelefone',
            align: 'center',
        },
        {
            title: 'CPF',
            dataIndex: 'cpf',
            key: 'cpf',
            align: 'center',
        },
    ]
}