import { Table, message, Spin, Button } from 'antd';
import * as React from 'react';
import { carregarGruposMusculares, ListaGrupoMuscular } from './actions';
import { colunasExercicio } from './columns';
import { ModalGrupoMuscular } from './ModalGrupoMuscular';

export const GruposMusculares = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [gruposMusculares, setGruposMusculares] = React.useState<ListaGrupoMuscular[]>([]);
    const [modalVisible, setModalVisible] = React.useState<boolean>(false);
    const [id, setId] = React.useState<number | null>(null);
    const [nome, setNome] = React.useState<string>('');

    const handleRowClick = (idValue: number, nomeValue: string) => {
        setId(idValue);
        setNome(nomeValue);
        showModal(true);
    }

    const handleOnClickCadastrarNovo = () => {
        setId(null);
        setNome('');
        showModal(true);
    }

    const showModal = (visible: boolean) => {
        setModalVisible(visible);
    }

    React.useEffect(() => {
        setLoading(true);
        carregarGruposMusculares()
            .then((resposta) => {
                setGruposMusculares(resposta);
            })
            .catch((erro) => {
                message.error("Erro ao carregar dados.");
                console.log(erro);
            });
        setLoading(false);
    }, [modalVisible])

    return (
        <>
            <Button
                onClick={handleOnClickCadastrarNovo}>
                Cadastrar Novo
            </Button>
            <Spin spinning={loading}>
                <Table
                    dataSource={gruposMusculares}
                    columns={colunasExercicio()}
                    onRow={(record) => ({
                        onClick: () => {
                            handleRowClick(record.id, record.nome);
                        },
                    })}
                />
            </Spin>
            <ModalGrupoMuscular
                showModal={showModal}
                modalVisible={modalVisible}
                id={id}
                nome={nome} />
        </>
    );
}