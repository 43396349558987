import { Tabs } from "antd";
import React from 'react';
import { TabelaFinancas } from "./TabelaFinancas";
import { TabelaMensalidades } from "./TabelaMensalidades";
import { AdicionaMovimento } from "./AdicionaMovimento";

const { TabPane } = Tabs;

export default function Alunos() {
    return (
        <Tabs type="card">
            <TabPane tab="Finanças" key="1">
                <TabelaFinancas />
            </TabPane>
            <TabPane tab="Mensalidades" key="2">
                <TabelaMensalidades />
            </TabPane>
            <TabPane tab="Lançamento" key="3">
                <AdicionaMovimento />
            </TabPane>
        </Tabs>
    )
}