import { DatePicker, Form, Input, Radio } from 'antd';
import React from 'react';

export default function CamposPessoa() {
    return (
        <>
            <Form.Item name="nome" label="Nome" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="dtNascimento" label="Data de nascimento" rules={[{ required: true }]}>
                <DatePicker />
            </Form.Item>
            <Form.Item name="sexo" label="Sexo" rules={[{ required: true }]}>
                <Radio.Group>
                    <Radio value="f">Feminino</Radio>
                    <Radio value="m">Masculino</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="email" label="E-mail" rules={[{ required: true, type: "email" }]}>
                <Input />
            </Form.Item>
            <Form.Item name="rg" label="RG" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="cpf" label="CPF" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="endereco" label="Endereço">
                <Input />
            </Form.Item>
            <Form.Item name="bairro" label="Bairro">
                <Input />
            </Form.Item>
            <Form.Item name="complemento" label="Complemento">
                <Input />
            </Form.Item>
            <Form.Item name="cidade" label="Cidade">
                <Input />
            </Form.Item>
            <Form.Item name="uf" label="UF">
                <Input />
            </Form.Item>
            <Form.Item name="cep" label="CEP">
                <Input />
            </Form.Item>
            <Form.Item name="telefone" label="Telefone">
                <Input />
            </Form.Item>
            <Form.Item name="segundo_telefone" label="Telefone adicional">
                <Input />
            </Form.Item>
        </>
    )
}