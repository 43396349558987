import * as Observable from 'rxjs';
import { endpointAlunos } from 'api/api';

export interface Aniversariante {
    nome: string;
    aniversario: string;
}

export function listaAniversariantesDoMes() {
    return Observable.defer(() => doListaAniversariantesDoMes()).toPromise();
}

async function doListaAniversariantesDoMes() {
    const result = await endpointAlunos.get('/aniversariantesDoMes');
    return result.data.map((r: Aniversariante) => {
        return {
            nome: r.nome,
            aniversario: r.aniversario,
        } as Aniversariante;
    }) as Aniversariante[];
}