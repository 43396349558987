import * as Observable from 'rxjs';
import { endpointFinancas } from 'api/api';

export function registraMovimento(values: any) {
    return Observable.defer(() => doRegistraMovimento(values)).toPromise();
}

async function doRegistraMovimento(values: any) {
    const result = await endpointFinancas.post('', values);
    return result.data;
}