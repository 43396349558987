import * as Observable from 'rxjs';
import { endpointExercicios } from 'api/api';

export function salvaGrupoMuscular(id: number | null, nome: string) {
    return Observable.defer(() => doSalvaGrupoMuscular(id, nome)).toPromise();
}

async function doSalvaGrupoMuscular(id: number | null, nome: string) {
    const result =  await endpointExercicios.post('/grupomuscular', {id, nome});
    return result.data;
}