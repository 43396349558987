import { Form, Input, InputNumber, Select } from 'antd';
import React from 'react';

const { Option } = Select;
const { TextArea } = Input;

export default function CamposExercicio() {
    return (
        <>
            <Form.Item name="nome" label="Nome" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="descricao" label="Descrição">
                <TextArea rows={3} />
            </Form.Item>
            <Form.Item name="grupoMuscular" label="Grupo Muscular">
                <Select defaultValue="Panturrilha" style={{ width: 120 }}>
                    <Option value="exemplo">Panturrilha</Option>
                    <Option value="ex">Panturrilha</Option>
                    <Option value="Panturrilha">Panturrilha</Option>
                </Select>
            </Form.Item>
            <Form.Item name="series" label="Séries">
                <InputNumber />
            </Form.Item>
            <Form.Item name="repeticoes" label="Repetições">
                <InputNumber />
            </Form.Item>
            <Form.Item name="tempoRepeticoes" label="Tempo Repetições(minutos)">
                <InputNumber />
            </Form.Item>
            <Form.Item name="carga" label="Carga">
                <InputNumber />
            </Form.Item>
            <Form.Item name="intervaloSerie" label="Intervalo Séries">
                <InputNumber />
            </Form.Item>
            <Form.Item name="intervaloFinal" label="Intervalo Final">
                <InputNumber />
            </Form.Item>
            <Form.Item name="linkImagem" label="Link para a imagem">
                <Input />
            </Form.Item>
            <Form.Item name="linkVideo" label="Link para o vídeo">
                <Input />
            </Form.Item>
        </>
    );
}