import * as Observable from 'rxjs';
import { endpointExercicios } from 'api/api';

export function cadastrarExercicio(values: any) {
    return Observable.defer(() => doCadastrarExercicio(values)).toPromise();
}

async function doCadastrarExercicio(values: any) {
    const result = await endpointExercicios.post('', values);
    return result;
}