import { Form, Input, InputNumber, Select } from 'antd';
import React from 'react';
import { mascaraMoeda, removeMascaraMoeda } from 'utils/masks';

const { Option } = Select;

export default function CamposMovimento() {
    return (
        <>
            <Form.Item name="descricao" label="Descricao" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="tipo" label="Tipo">
                <Select defaultValue="ENTRADA" style={{ width: 120 }}>
                    <Option value="ENTRADA">Entrada</Option>
                    <Option value="SAIDA">Saída</Option>
                </Select>
            </Form.Item>
            <Form.Item name="valor" label="Valor">
                <InputNumber
                    style={{width: 115}}
                    min={0}
                    formatter={value => mascaraMoeda(value!.toString())}
                    parser={value => value ? removeMascaraMoeda(value) : ''}
                    onChange={value => console.log(value)}
                />
            </Form.Item>
            {/* <Form.Item name="agendamento" label="Agendar para:">
                <DatePicker />
            </Form.Item> */}
        </>
    );
}