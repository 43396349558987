export const mascaraCpf = (value: string) => {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
}

export const removeMascaraCpf = (value: string) => {
  return value.replace(/(\.)|(-)/g, '');
}

export const mascaraTelefone = (value: string | null) => {
  if(value == null) {
    return "";
  }
  
  if(value.length === 11) {
    return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
  } else if (value.length === 10) {
    return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
  }
  return value;
}

export const mascaraMoeda = (value: string) => {
  if(value === undefined || value === '') {
    return 'R$ 0,00';
  }

  var v = parseInt(removeMascaraMoeda(value), 10).toString();

  if (v.length > 2) {
    const reais = v.slice(0, -2);
    const centavos = v.slice(-2);
    v = reais.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + centavos;
  } else if(v.length === 2) {
    v = '0,' + v;
  } else if (v.length === 1) {
    v = '0,0' + v;
  }

  return 'R$ ' + v;
}

export const removeMascaraMoeda = (value: string) => {
  return value.replace(/\D/g,'');
}