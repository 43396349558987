import * as React from 'react';
import { Form, Button, message } from 'antd';
import CamposMovimento from 'components/fields/CamposMovimento';
import { registraMovimento } from './actions';

export const AdicionaMovimento = () => {
    const [ form ] = Form.useForm();

    const onFinish = (values: any) => {
        registraMovimento(values)
            .then(() => {
                form.resetFields();
                message.success("Movimento registrado.");
            })
            .catch((erro) => {
                console.log(erro);
                message.error("Erro inesperado.");
            });
    }

    return (
        <Form
            onFinish={onFinish}>
            <CamposMovimento />
            <Button type="primary" htmlType="submit">
                Cadastrar
            </Button>
        </Form>
    );
}