import * as Observable from 'rxjs';
import { endpointFinancas } from 'api/api';
import { Movimento } from 'models/Movimentos';

export function carregarTabela() {
    return Observable.defer(() => doCarregarTabela()).toPromise();
}

async function doCarregarTabela() {
    const result = await endpointFinancas.get('');
    return result.data.map(( r: Movimento ) => {
        return {
            id: r.id,
            valor: r.valor,
            descricao: r.descricao,
            tipo: r.tipo,
            dataHoraLancamento: r.dataHoraLancamento,
            dataHoraLiquidacao: r.dataHoraLiquidacao,
            dataAgendamento: r.dataAgendamento,
            status: r.status,
        } as Movimento;
    }) as Movimento[];
}

export function pesquisarMovimentos(values: any) {
    return Observable.defer(() => doPesquisarMovimentos(values)).toPromise();
}

async function doPesquisarMovimentos(values: any) {
    const result = await endpointFinancas.post('/procurar', values);
    return result.data.map(( r: Movimento ) => {
        return {
            id: r.id,
            valor: r.valor,
            descricao: r.descricao,
            tipo: r.tipo,
            dataHoraLancamento: r.dataHoraLancamento,
            dataHoraLiquidacao: r.dataHoraLiquidacao,
            dataAgendamento: r.dataAgendamento,
            status: r.status,
        } as Movimento;
    }) as Movimento[];
}