import React from 'react';
import Alunos from "../pages/Alunos";
import Financas from "../pages/Financas";
import { Home } from "../pages/Home";
import { Switch, Route } from 'react-router-dom';
import { Exercicios } from '../pages/Exercicios';
import { Gerenciamento } from 'pages/Gerenciamento';

export const Conteudo = () => {
    return (
        <div
            className="card-container"
            style={{
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Switch>
                <Route exact path="/" render={() => <Home/>} />
                <Route path="/alunos" render={() => <Alunos />} />
                <Route path="/financas" render={() => <Financas />} />
                <Route path="/exercicios" render={() => <Exercicios />} />
                <Route path="/gerenciamento" render={() => <Gerenciamento />} />
            </Switch>
        </div>
    )
}