import { Tabs } from "antd";
import React from 'react';
import { TabelaExercicios } from "./TabelaExercios";
import { CadastrarExercio } from "./Cadastrar";

const { TabPane } = Tabs;

export const Exercicios = () => {
    return (
        <Tabs type="card">
            <TabPane tab="Cadastrar" key="1">
                <CadastrarExercio />
            </TabPane>
            <TabPane tab="Procurar" key="2">
                <TabelaExercicios />
            </TabPane>
        </Tabs>
    )
}