import React from "react"
import { Tabs } from "antd"
import { GruposMusculares } from './GruposMusculares'

const { TabPane } = Tabs;

export const Gerenciamento = () => {
    return (
        <Tabs type="card">
            <TabPane tab="Campos Predefinidos" key="1">
                <GruposMusculares />
            </TabPane>
        </Tabs>
    )
}