import React from "react";
import { Modal, Spin, Form, Input, Button, message } from "antd";
import { salvaGrupoMuscular } from "./actions";

export interface InjectedProps {
    showModal: (visible: boolean) => void;
    modalVisible: boolean;
    id: number | null;
    nome: string;
}

export const ModalGrupoMuscular = (props: InjectedProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [textoBotao, setTextoBotao] = React.useState<string>('');

    React.useEffect(() => {
        if(props.id == null) {
            setTextoBotao('Salvar');
        } else {
            setTextoBotao('Atualizar');
        }
    }, [props.id]);

    const handleFormFinish = (value: any) => {
        setLoading(true);
        salvaGrupoMuscular(props.id, value.nome)
        .then(() => {
            message.success('Operação realizada.');
            props.showModal(false);
        })
        .catch(() => {
            message.error('Erro inesperado.');
        });
        setLoading(false);
    }

    return (
        <Spin spinning={loading}>
            <Modal
                visible={props.modalVisible}
                onCancel={() => props.showModal(false)}
            >
                <Form
                    onFinish={handleFormFinish}>
                    <Form.Item name='nome' label='nome' rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                        {textoBotao}
                    </Button>
                </Form>
            </Modal>
        </Spin>
    )
}