import { Layout } from 'antd';
import React from 'react';
import './App.css';
import { MenuLateral } from './components/MenuLateral';
import { Conteudo } from './components/Conteudo';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const { Content, Sider } = Layout;

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Sider
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
            }}
          >
            <MenuLateral />
          </Sider>
          <Layout
            style={{ marginLeft: 200 }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: "100vh",
              }}
            >
              <Conteudo />
            </Content>
          </Layout>
        </Layout>
      </BrowserRouter>
    );
  }
}