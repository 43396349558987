import React from "react"
import CamposExercicio from "components/fields/CamposExercicio"
import { Button, message, Form } from "antd"
import { cadastrarExercicio } from "./actions"

export const CadastrarExercio = () => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        cadastrarExercicio(values)
        .then(() => {
            form.resetFields();
            message.success("Exercício cadastrado.");
        })
        .catch(() => {
            message.error("Erro inesperado ao realizar cadastro.");
        });
    }

    return(
        <Form
        onFinish={onFinish}
        >
            <CamposExercicio />
            <Button type="primary" htmlType="submit">
                Cadastrar
            </Button>
        </Form>
    )
}