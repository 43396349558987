import * as Observable from 'rxjs';
import { endpointAlunos } from 'api/api';
import { Aluno } from 'models/Aluno';

export function carregarDadosAluno(cpf: string) {
    return Observable.defer(() => doCarregarDadosAluno(cpf)).toPromise();
}

async function doCarregarDadosAluno(cpf: string) {
    const result = await endpointAlunos.get('/' + cpf);
    return result.data as Aluno;
}