export const columns = [
    // {
    //     title: 'Id',
    //     dataIndex: 'id',
    //     key: 'id',
    // },
    {
        title: 'Exercício',
        dataIndex: 'nome',
        key: 'nome',
    },
    {
        title: 'Grupo Muscular',
        dataIndex: 'grupoMuscular',
        key: 'grupoMuscular',
    },
];