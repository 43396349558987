import { Menu } from "antd";
import * as React from 'react';
import { Link } from "react-router-dom";

export const MenuLateral = () => {
    return (
        <Menu
            mode="inline"
            theme="dark"
            inlineIndent={0}
        >
            <Menu.Item>
                <span>Home</span>
                <Link to="/" />
            </Menu.Item>
            <Menu.Item>
                <span>Alunos</span>
                <Link to="/alunos" />
            </Menu.Item>
            <Menu.Item>
                <span>Finanças</span>
                <Link to="/financas" />
            </Menu.Item>
            <Menu.Item>
                <span>Exercícios</span>
                <Link to="/exercicios" />
            </Menu.Item>
            <Menu.Item>
                <span>Gerenciamento</span>
                <Link to="/gerenciamento" />
            </Menu.Item>
        </Menu>
    );
}