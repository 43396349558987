import React from 'react';
import { Table, Input, Spin, message } from 'antd';
import { columns } from './columns';
import { carregarTabela, ListaExercicio } from './actions';

const { Search } = Input;

export const TabelaExercicios = () => {
  const [listaExercicio, setListaExercicio] = React.useState<ListaExercicio[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    carregarTabela()
    .then((resposta) => {
      setListaExercicio(resposta);
    })
    .catch((erro) => {
      message.error("Erro inesperado.");
      console.log(erro);
    })
    .finally(() => {
      setLoading(false);
    })
  }, [])

  return (
    <>
      <Search
        placeholder="Pesquisar"
        onSearch={value => console.log(value)}
        style={{ width: 200 }}
      />
      <Spin spinning={loading}>
        <Table
          dataSource={listaExercicio}
          columns={columns}
          rowKey='id'
        />
      </Spin>
    </>
  );
}